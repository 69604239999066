import React from "react";
import IMAGE2 from "../../assets/images/about/5.jpeg";

export default function CustomerService() {
  return (
    <section className="py-20">
      <div className="container">
        <div className="grid md:grid-cols-3 border-[6px] border-primary-main rounded-2xl">
          <div className="hidden md:block">
            <img
              src={IMAGE2}
              className="h-full w-full object-cover rounded-l-2xl"
              alt=""
            />
          </div>
          <div className="md:col-span-2 bg-dark-gray rounded-r-2xl">
            <div className="pl-2 pr-2 md:pl-16 md:pt-6 md:pr-4 md:pb-2">
              <h3 className="font-bold text-3xl md:text-5xl text-white p-5 md:p-0 text-center md:text-left">
                <span className="text-primary-main mr-2 md:mr-0">
                  Customer Service
                </span>
                <br className="hidden md:inline-block" />
                Non-Voice
              </h3>

              <div className="mt-4">
                {items?.map((el) => (
                  <div
                    key={el?.title}
                    className="grid grid-cols-2 items-center gap-1 py-5"
                  >
                    <h4
                      className="text-right pr-7 text-white font-bold text-xl"
                      dangerouslySetInnerHTML={{ __html: el?.title }}
                    />
                    <p className="text-white relative after:absolute after:w-0.5 after:h-full after:bg-primary-main after:-left-3 after:top-0 py-3 pb-5">
                      {el?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const items = [
  {
    title: "Chat, Email & Help Desk <br /> Support",
    description:
      "Elevate your customer service experience with our comprehensive support offerings, featuring chat, email, and help desk support.",
  },
  {
    title: "Social Media <br /> Support",
    description:
      "Ignite your social media presence, gain a competitive edge with our top-tier social media support services, designed to elevate your brand’s online engagement and customer satisfaction.",
  },
  {
    title: "Enquiry Handling & After <br /> Sales Care",
    description:
      "Streamline your customer interactions with our expert inquiry handling services, designed to deliver efficient and exceptional support, leaving no inquiry unanswered.",
  },
  {
    title: "CRM Support",
    description:
      "Experienced CRM users are the masters of customer relationship management software. They navigate, customize, and maximize its potential to revolutionize customer interactions and drive business success.",
  },
];
