import React, { useState } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Navbar({ className }) {
  const [open, setOpen] = useState(false);

  return (
    <header className={`flex w-full items-center absolute z-10 ${className}`}>
      <div className="container py-4">
        <div className="relative flex items-center justify-between">
          <div className="px-4">
            <Link to="/" className="block w-full">
              {/* <img src="" alt="logo" className="h-16" /> */}
              <span className="font-bold text-2xl text-white font-serif">
                Code3
              </span>
            </Link>
          </div>
          <div className="flex items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                id="navbarToggler"
                className={` ${
                  open && "navbarTogglerActive"
                } absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
              </button>
              <nav
                id="navbarCollapse"
                className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white px-6 shadow dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none lg:dark:bg-transparent ${
                  !open && "hidden"
                } `}
              >
                <ul className="block lg:flex">
                  <ListItem NavLink="/about-us">About Us</ListItem>
                  <ListItem NavLink="#">
                    <span className="flex items-center gap-1">
                      Service <IoChevronDownOutline className="mt-1" />
                    </span>
                    <div className="bg-white text-dark-main absolute top-full left-0 h-0 -translate-y-0 overflow-y-hidden transition-all nav-child-link">
                      Hello
                    </div>
                  </ListItem>
                  <ListItem NavLink="#">Customer Service (Non-Voice)</ListItem>
                  <ListItem NavLink="#">
                    <span className="flex items-center gap-1">
                      Industries <IoChevronDownOutline className="mt-1" />
                    </span>
                    <div className="bg-white text-dark-main absolute top-full left-0 h-0 -translate-y-0 overflow-y-hidden transition-all nav-child-link">
                      Hello
                    </div>
                  </ListItem>
                  {/* <ListItem NavLink="#">
                    <span className="flex items-center gap-1">
                      Company <IoChevronDownOutline className="mt-1" />
                    </span>
                    <div className="bg-white text-dark-main absolute top-full left-0 h-0 -translate-y-0 overflow-y-hidden transition-all nav-child-link">
                      Hello
                    </div>
                  </ListItem> */}
                  <ListItem NavLink="#">Contact</ListItem>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

const ListItem = ({ children, NavLink, className, ...rest }) => {
  return (
    <li {...rest}>
      <Link
        to={NavLink}
        className={`flex py-5 text-base font-medium text-white hover:text-primary-main lg:ml-12 lg:inline-flex relative nav-main-link`}
      >
        {children}
      </Link>
    </li>
  );
};
