import React from "react";
import IMG from "../../assets/images/about-us/who-we-are.jpeg";

export default function WhoWeAre() {
  return (
    <section className="bg-dark-normal">
      <div className="container py-5 md:py-20">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-24">
          <div>
            <img className="h-[650px] w-full object-cover" src={IMG} alt="" />
          </div>
          <div className="md:col-span-2">
            <div>
              <h3 className="font-bold text-3xl md:text-5xl text-primary-main uppercase">
                WHO WE ARE
              </h3>
              <h5 className="font-bold text-2xl md:text-4xl text-white pl-10 my-14 relative">
                Your Business, Our Expertise Unleash the Possibilities
                <div className="absolute w-[2px] h-full bg-white top-0 left-0" />
              </h5>
              <p className="text-white/50 font-light text-lg">
                Bridge the gaps and empower limitless possibilities – we are the
                driving force behind global connectivity. At Acote BPO, we are
                the catalysts of extraordinary possibilities. With a deep
                commitment to excellence and a relentless pursuit of success, we
                specialize in delivering unparalleled support to organizations
                worldwide. Our team of exceptional talents is driven by a
                passion for cultivating greatness and fueling sustainable
                growth. With us by your side, you gain a strategic partner
                dedicated to igniting innovation, fostering collaboration, and
                propelling your organization to new heights. Experience the
                difference with Acote BPO by joining us in this remarkable
                journey as we empower connections and redefine the future of
                global support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
