import React from "react";
import { Helmet } from "react-helmet";

const defaultTitle = "Code3";

export default function AppLayout({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      </Helmet>

      <main className="bg-dark-main">{children}</main>
    </>
  );
}
