import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import React, { useEffect, useState } from "react";
import HERO_IMAGE from "../../assets/images/hero.jpg";
import { particlesConfig } from "../../config/particles.config";
import Navbar from "../Navbar";

export default function Hero() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <section
      className="inset-0 bg-cover bg-center z-0 min-h-[560px] h-[600px] md:h-screen w-full relative"
      style={{ backgroundImage: `url(${HERO_IMAGE})` }}
    >
      <Navbar />
      <div className="backdrop-blur-sm h-full w-full md:pt-12">
        {init && <Particles id="tsparticles" options={particlesConfig} />}

        <div className="container flex justify-center items-center h-full">
          <div>
            <h1 className="text-white font-bold text-4xl md:text-7xl max-w-5xl">
              Your Business, Our Expertise Unleash the Possibilities
            </h1>
            <p className="text-white text-lg mt-7">
              At Acote BPO, one of the top{" "}
              <a
                href="/"
                className="font-semibold transition-colors text-secondary-main hover:text-secondary-normal"
              >
                BPO company in USA and Europe
              </a>
              . we’re dedicated to propelling your success. Our tailored
              solutions and experienced team drive growth and efficiency for
              your business. As an extension of your team, we unlock new
              opportunities and deliver unparalleled support. Experience the
              power of collaboration and achieve new heights with Acote BPO.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
