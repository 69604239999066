import React from "react";
import About from "../components/Home/About";
import About2 from "../components/Home/About2";
// import Banner from "../components/Home/Banner";
import CustomerService from "../components/Home/CustomerService";
import Hero from "../components/Home/Hero";
import MissionVision from "../components/Home/MissionVision";
import OurDiverse from "../components/Home/OurDiverse";
import Partners from "../components/Home/Partners";
import Services from "../components/Home/Services";
import Testimonials from "../components/Home/Testimonials";
import WhyBangladesh from "../components/Home/WhyBangladesh";
import WorkingProcess from "../components/Home/WorkingProcess";
import AppLayout from "../layouts/AppLayout";

export default function Home() {
  return (
    <AppLayout title="Home">
      <Hero />
      <About />
      <About2 />
      <Services />
      <CustomerService />
      <WorkingProcess />
      <MissionVision />
      <WhyBangladesh />
      {/* <Banner /> */}
      <OurDiverse />
      <Partners />
      <Testimonials />
    </AppLayout>
  );
}
