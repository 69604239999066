import React from "react";
import { Slide } from "react-awesome-reveal";
import IMAGE1 from "../../assets/images/about/4.jpeg";
import IMAGE2 from "../../assets/images/about/5.jpeg";
import IMAGE3 from "../../assets/images/about/6.jpeg";

export default function About2() {
  return (
    <section className="md:pb-8">
      <div className="container pb-10">
        <div className="grid grid-cols-1 md:grid-cols-12 items-center gap-8">
          <div />
          <div className="md:col-span-6 grid md:grid-cols-2 items-center gap-9">
            <div className="hidden md:block">
              <Slide direction="right" triggerOnce>
                <img
                  className="rounded-xl h-[28rem] w-full object-cover"
                  src={IMAGE1}
                  alt=""
                />
              </Slide>
            </div>
            <div className="grid gap-9">
              <div className="hidden md:block">
                <Slide direction="up" triggerOnce>
                  <img
                    className="rounded-xl h-[28rem] w-full object-cover"
                    src={IMAGE2}
                    alt=""
                  />
                </Slide>
              </div>
              <Slide direction="down" triggerOnce>
                <img
                  className="rounded-xl h-60 w-full object-cover"
                  src={IMAGE3}
                  alt=""
                />
              </Slide>
            </div>
          </div>
          <div className="md:col-span-5">
            <h3 className="font-bold text-3xl text-white">
              <span className="text-primary-main">
                TOP BPO Company in USA and Europe
              </span>
            </h3>
            <ul className="mt-5 list-inside pl-2 list-disc">
              {aboutLists.map((el) => (
                <li className="text-primary-main text-lg my-2" key={el}>
                  <span className="text-white">{el}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div>
          <div className="bg-white px-9 py-5 font-bold mt-4">
            Acote services at a glance
          </div>

          <p className="px-5 md:px-9 py-5 text-white/50 font-light text-lg mt-5">
            Acote BPO is a top BPO services company in the USA and Europe. Our
            major services are online data entry BPO, data processing BPO,
            Property Management BPO, accounting service BPO, back office support
            service BPO, Ecommerce Services BPO, data entry service BPO, data
            analytics BPO, back office support BPO, content categorization BPO,
            annotation BPO, virtual assistant BPO, menu building BPO, lead
            generation services BPO, and cx management strategy BPO service.
          </p>
        </div>
      </div>
    </section>
  );
}

const aboutLists = [
  "Acote BPO is one of the Top BPO company in USA and Europe. Reputable magazines like Forbes view this technique as advantageous to firms of all sizes. These provide compelling evidence for why the sector is still thriving today.",
  "Best BPO company in USA can make sure the exact support in time",
  "Acote BPO one of the renowned BPO company and leading this industry last 10 years.",
  "Acotebpo is one of the top BPO company in America. Businesses look for methods to take advantage of new technologies while simultaneously lowering costs and boosting efficiency as digitization advances.",
];
