import React from "react";
import Marquee from "react-fast-marquee";
import BG from "../../assets/images/hero.jpg";
import Image1 from "../../assets/images/home/patner/Acote-k-market.jpg";
import Image2 from "../../assets/images/home/patner/Hyundai-Electronics-acote.jpg";
import Image3 from "../../assets/images/home/patner/Logo-for-web-233x118-01.jpg";
import Image4 from "../../assets/images/home/patner/Logo-for-web-233x118-02.jpg";
import Image5 from "../../assets/images/home/patner/Logo-for-web-233x118-05.jpg";
import Image6 from "../../assets/images/home/patner/Logo-for-web-233x118-06.jpg";
import Image7 from "../../assets/images/home/patner/kbcci-acote.jpg";
import Image8 from "../../assets/images/home/patner/lg-logo.jpg";
import Image9 from "../../assets/images/home/patner/smtv-acote.jpg";
import Image10 from "../../assets/images/home/patner/tbc-acote.jpg";

export default function Partners() {
  return (
    <section
      className="bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: `linear-gradient(rgba(80, 113, 75, 0.5),rgba(80, 113, 75, 0.5)),url(${BG})`,
      }}
    >
      <div className="backdrop-blur-md w-full h-full py-10 md:py-24">
        <div className="container">
          <h2 className="text-center text-white font-bold text-2xl md:text-5xl drop-shadow-md">
            Our Clients & <span className="text-primary-main">Partners</span>
          </h2>
          <p className="text-center text-white/60 text-sm md:text-lg mt-3 md:mt-7 drop-shadow-md">
            Driving BPO for leading brands
          </p>

          <div className="mt-8 md:mt-12">
            <Marquee
              className="w-full"
              gradient={true}
              gradientColor={[13, 13, 13]}
              gradientWidth={300}
            >
              <div className="flex justify-center items-center gap-10">
                {partnersList?.map((el, idx) => (
                  <div
                    className={`h-16 md:h-28 ${
                      idx === partnersList?.length - 1 ? "pr-10" : ""
                    }`}
                  >
                    <img className="h-full" src={el?.image} alt="" />
                  </div>
                ))}
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
}

const partnersList = [
  { id: 1, image: Image1 },
  { id: 2, image: Image2 },
  { id: 3, image: Image3 },
  { id: 4, image: Image4 },
  { id: 5, image: Image5 },
  { id: 6, image: Image6 },
  { id: 7, image: Image7 },
  { id: 8, image: Image8 },
  { id: 9, image: Image9 },
  { id: 10, image: Image10 },
];
