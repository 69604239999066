import React from "react";
import CountUp from "react-countup";
import BG from "../../assets/images/home/galaxy.jpg";

export default function OurDiverse() {
  return (
    <section
      className="py-10 md:py-20 bg-center bg-cover bg-no-repeat bg-fixed bg-blend-darken"
      style={{
        backgroundImage: `linear-gradient(rgba(000, 000, 000, 0.7),rgba(000, 000, 000, 0.7)),url(${BG})`,
      }}
    >
      <div className="container grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-4">
        <div className="col-span-2 md:col-span-1 pb-3 md:pb-0">
          <div className="flex justify-center border-r-2 border-r-primary-main">
            <h3 className="font-bold text-xl md:text-4xl text-white leading-relaxed">
              <span className="text-primary-main">Our Diverse </span>
              Pool Of Talents
            </h3>
          </div>
        </div>
        {countData?.map((el) => (
          <div
            className="flex justify-center items-center text-center"
            key={el?.title}
          >
            <div className="w-full">
              <h4 className="text-white/80 font-normal text-5xl">
                <CountUp
                  start={0}
                  end={el?.count}
                  separator=""
                  duration={4}
                  delay={2}
                  formattingFn={(value) => value + "+"}
                />
              </h4>

              <h6
                className="text-primary-main font-semibold text-[10px] tracking-[1px] md:tracking-[4px] mt-4"
                dangerouslySetInnerHTML={{ __html: el?.title }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const countData = [
  {
    title: "TECH SAVVY <br /> EXPERT",
    count: 100,
  },
  {
    title: "DATA-DRIVEN <br /> EXPERT",
    count: 250,
  },
  {
    title: "CUSTOMER-FOCUSED <br /> EXPERT",
    count: 150,
  },
  {
    title: "BUSINESS SUPPORT <br /> EXPERT",
    count: 200,
  },
];
