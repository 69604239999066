import React from "react";
import Slider from "react-slick";
import BG from "../../assets/images/home/partner.jpeg";

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      className="bg-center bg-cover bg-no-repeat bg-fixed py-10 md:py-20"
      style={{
        backgroundImage: `linear-gradient(rgba(000, 000, 000, 0.9),rgba(000, 000, 000, 0.9)),url(${BG})`,
      }}
    >
      <div className="text-center flex justify-center">
        <h3 className="font-bold text-2xl md:text-5xl text-white border-b-4 border-b-primary-main border-solid pb-2 px-4">
          <span className="text-primary-main">Clients</span>{" "}
          <br className="hidden md:inline-block" />
          Testimonials
        </h3>
      </div>

      <div className="container mt-10">
        <div className="slider-container">
          <Slider {...settings}>
            {testimonials?.map((el) => (
              <div className="px-2">
                <div className="bg-dark-main min-h-72 p-5 text-center grid justify-between rounded-md">
                  <p className="text-white italic">{el?.message}</p>
                  <h4 className="text-primary-main mt-auto">
                    {el?.designation}
                  </h4>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

const testimonials = [
  {
    message:
      "I recently had the pleasure of partnering with ACOTE BPO for our business process outsourcing needs, and I am thrilled to share my exceptional experience. From the moment we engaged with their team, it was evident that they were dedicated to delivering top-notch service and exceeding our expectations.",
    designation: "CEO",
  },
  {
    message:
      "They provided us with a comprehensive range of outsourcing solutions that catered perfectly to our requirements. Their team took the time to understand our business processes, challenges, and goals, allowing them to tailor their services to meet our specific needs. The level of customization they offered was impressive and best value for our investment.",
    designation: "Marketing Manager",
  },
  {
    message:
      "My experience with this company was nothing short of outstanding. Their commitment to excellence, personalized approach, effective communication, and exceptional results set them apart as a trusted outsourcing partner. I highly recommend their services to any organization seeking to enhance efficiency, reduce costs, and achieve success.",
    designation: "Operation Head",
  },
  {
    message:
      "The team at ACOTE displayed exceptional professionalism and knowledge. They took the time to understand our specific requirements and designed tailored solutions that perfectly align with our business needs. Their attention to detail and commitment to delivering high-quality outputs have exceeded our expectations.",
    designation: "CSM",
  },
];
