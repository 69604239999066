import React from "react";
import { Slide } from "react-awesome-reveal";
import IMAGE1 from "../../assets/images/about/1.jpeg";
import IMAGE2 from "../../assets/images/about/2.jpeg";
import IMAGE3 from "../../assets/images/about/3.jpeg";

export default function About() {
  return (
    <section>
      <div className="container py-5 md:pb-10 md:pt-28">
        <div className="grid grid-cols-1 md:grid-cols-12 items-center gap-8">
          <div />
          <div className="md:col-span-6 grid md:grid-cols-2 items-center gap-9">
            <div className="hidden md:block">
              <Slide direction="right" triggerOnce>
                <img
                  className="rounded-xl h-72 w-full object-cover"
                  src={IMAGE1}
                  alt=""
                />
              </Slide>
            </div>
            <div className="grid gap-9">
              <div className="hidden md:block">
                <Slide direction="up" triggerOnce>
                  <img
                    className="rounded-xl h-72 w-full object-cover"
                    src={IMAGE2}
                    alt=""
                  />
                </Slide>
              </div>
              <div>
                <Slide direction="down" triggerOnce>
                  <img
                    className="rounded-xl h-72 w-full object-cover"
                    src={IMAGE3}
                    alt=""
                  />
                </Slide>
              </div>
            </div>
          </div>
          <div className="md:col-span-5">
            <h3 className="font-bold text-4xl md:text-5xl text-white">
              <span className="text-primary-main">Who</span> We Are
            </h3>
            <ul className="mt-5 list-inside pl-2 list-disc">
              {aboutLists.map((el) => (
                <li className="text-primary-main text-lg my-2" key={el}>
                  <span className="text-white">{el}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

const aboutLists = [
  "Bridging the gaps, empowering limitless possibilities.",
  "Unparalleled support for global organizations",
  "Igniting innovation and fostering collaboration",
  "Driving sustainable growth for businesses",
  "Redefining the future of global support",
];
