import React from "react";
import { Bounce, Slide } from "react-awesome-reveal";
import Map from "../../assets/images/map.png";
import Image1 from "../../assets/images/whyBD/1.png";
import Image2 from "../../assets/images/whyBD/2.png";
import Image3 from "../../assets/images/whyBD/3.png";
import Image4 from "../../assets/images/whyBD/4.png";
import Image5 from "../../assets/images/whyBD/5.png";
import Image6 from "../../assets/images/whyBD/6.png";
import Image7 from "../../assets/images/whyBD/7.png";

export default function WhyBangladesh() {
  return (
    <section className="py-20">
      <div className="container">
        <div className="flex justify-center">
          <h3 className="font-bold text-5xl text-white">
            <span className="text-primary-main">Why</span> <br />
            BANGLADESH
          </h3>
        </div>

        <div className="py-60 flex justify-center">
          <div className="w-[350px] h-[350px] relative">
            <Slide direction="up">
              <div className="w-[350px] h-[350px] rounded-full p-3 border border-primary-main">
                <div className="w-full h-full p-5 bg-dark-normal rounded-full">
                  <img
                    className="h-full w-full object-contain"
                    src={Map}
                    alt="Map"
                  />
                </div>
              </div>
            </Slide>

            <div className="absolute -top-36 -left-72 ">
              <Slide direction="left">
                <div className="w-96 min-h-40 bg-dark-normal rounded-lg border-l-2 border-l-primary-main border-solid">
                  <div className="w-[86%] p-3 text-right relative">
                    <img
                      className="absolute -right-[6.2rem]"
                      src={Image1}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Time Zone Advantage
                    </h6>
                    <p className="text-white py-1.5 leading-tight ">
                      Gain a significant edge with our Bangladeshi BPO company
                      working during your business hours for seamless
                      communication and real-time support.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>

            <div className="absolute -top-36 -right-72">
              <Slide direction="right">
                <div className="w-96 min-h-40 bg-dark-normal rounded-lg flex justify-end border-r-2 border-r-primary-main border-solid">
                  <div className="w-[86%] p-3 relative">
                    <img
                      className="absolute -left-[6.2rem]"
                      src={Image2}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Language Proficiency
                    </h6>
                    <p className="text-white pt-1.5 leading-tight">
                      Our workforce’s widespread fluency in English ensures
                      smooth and effective communication, facilitating seamless
                      interaction with your clients and customers.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>

            <div className="absolute top-1/4 -translate-y-1/4 -left-96">
              <Slide direction="left">
                <div className="w-96 min-h-36 bg-dark-normal rounded-lg border-l-2 border-l-primary-main border-solid">
                  <div className="w-[86%] p-3 text-right relative">
                    <img
                      className="absolute -right-[6.2rem]"
                      src={Image3}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Skilled Workforce
                    </h6>
                    <p className="text-white py-1.5 leading-tight ">
                      Our talented professionals in Bangladesh handle diverse
                      BPO services, delivering exceptional performance through
                      rigorous training.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>

            <div className="absolute top-1/4 -translate-y-1/4 -right-96">
              <Slide direction="right">
                <div className="w-96 min-h-36 bg-dark-normal rounded-lg flex justify-end border-r-2 border-r-primary-main border-solid">
                  <div className="w-[86%] p-3 relative">
                    <img
                      className="absolute -left-[6.2rem]"
                      src={Image4}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Government Support
                    </h6>
                    <p className="text-white pt-1.5 leading-tight">
                      Bangladesh fosters BPO industry growth with favorable
                      policies, incentives & infrastructure development.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>

            <div className="absolute -bottom-10 -left-[365px]">
              <Slide direction="left">
                <div className="w-[365px] min-h-[155px] bg-dark-normal rounded-lg border-l-2 border-l-primary-main border-solid">
                  <div className="w-[86%] p-3 text-right relative">
                    <img
                      className="absolute -right-[6.2rem]"
                      src={Image5}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Cultural Compatibility
                    </h6>
                    <p className="text-white py-1.5 leading-tight ">
                      Bangladesh’s alignment with Western culture fosters
                      collaboration and better customer experiences.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>

            <div className="absolute -bottom-10 -right-[365px]">
              <Slide direction="right">
                <div className="w-[365px] min-h-[155px] bg-dark-normal rounded-lg flex justify-end border-r-2 border-r-primary-main border-solid">
                  <div className="w-[86%] p-3 relative">
                    <img
                      className="absolute -left-[6.2rem]"
                      src={Image6}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Cost-Effective Solutions
                    </h6>
                    <p className="text-white pt-1.5 leading-tight">
                      Bangladesh offers exceptional cost-effectiveness with
                      competitive rates, ensuring maximum value for your
                      investment.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>

            <div className="absolute -bottom-56 left-2/4 -translate-x-2/4">
              <Bounce>
                <div className="w-[420px] min-h-[170px] bg-dark-normal rounded-lg border-x-2 border-x-primary-main border-solid">
                  <div className="p-5 pt-11 text-center relative">
                    <img
                      className="absolute left-2/4 -translate-x-2/4 -top-14"
                      src={Image7}
                      alt="..."
                    />
                    <h6 className="text-primary-main font-medium text-lg">
                      Growing BPO Industry
                    </h6>
                    <p className="text-white pt-1.5 leading-tight">
                      Partner with us to tap into Bangladesh’s vibrant and
                      expanding BPO industry, benefiting from technological
                      advancements and best practices.
                    </p>
                  </div>
                </div>
              </Bounce>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
