import React from "react";
import { Slide } from "react-awesome-reveal";
import TitleBar from "../common/TitleBar";

export default function WorkingProcess() {
  return (
    <section className="py-10">
      <div className="container relative">
        <div className="absolute top-36 left-[30rem] -rotate-[48deg]">
          <div className="w-[1px] h-96 bg-primary-main" />
          <div className="w-7 h-7 rounded-full bg-primary-main absolute -bottom-3 -left-3" />
        </div>

        <div className="absolute top-[21.7rem] left-[34rem] -rotate-[48deg]">
          <div className="w-[1px] h-96 bg-primary-main" />
          <div className="w-7 h-7 rounded-full bg-primary-main absolute -top-3 -left-3" />
        </div>
        <div className="flex flex-wrap relative z-10">
          <div className="w-full md:w-[36.964%] md:p-4 order-2 md:order-1">
            <Slide direction="left">
              <WorkingCard
                num={"01"}
                title={"REQUIREMENT FULFILLMENT"}
                list={[
                  "Setting up Goals, Objectives & SLA’s",
                  "Quality Control",
                  "Continuous Training",
                ]}
              />
            </Slide>
          </div>
          <div className="w-full md:w-[63.036%] order-1 md:order-2">
            <TitleBar
              title="Steps"
              mainTitle={
                <>
                  <span className="text-primary-main">Our</span> Working
                  <br className="hidden md:inline-block" />
                  <span className="pl-2 md:pl-0">Process</span>
                </>
              }
            />
          </div>
        </div>

        <div className="flex relative z-10 py-6 md:py-0">
          <div className="hidden md:block md:w-[25.206%]" />
          <div className="w-full md:w-[42.197%] md:p-4">
            <WorkingCard
              num={"02"}
              title={"Setup"}
              list={[
                "Selecting The Right People",
                "Setting Up Systems",
                "Implementation of Guidelines",
                "Training",
              ]}
            />
          </div>
          <div className="hidden md:block md:w-[32.263%]" />
        </div>

        <div className="flex relative z-10">
          <div className="hidden md:block md:w-[52.973%]" />
          <div className="w-full md:w-[43.178%] md:p-4">
            <Slide direction="right">
              <WorkingCard
                num={"03"}
                title={"Start of Operations"}
                list={["Project Details", "Team Size", "Company Overview"]}
              />
            </Slide>
          </div>
          <div className="hidden md:block md:w-[3.515%]" />
        </div>
      </div>
    </section>
  );
}

const WorkingCard = ({ num, title, list }) => {
  return (
    <div
      className="grid md:grid-cols-3 gap-5 p-4 md:py-3 md:pr-4 bg-dark-gray rounded-lg shadow-xl"
      style={{ boxShadow: "2px 0 0 0 #E3A035" }}
    >
      <div
        className="text-[9.5rem] font-bold text-dark-main leading-none"
        style={{
          textShadow: "0 0 0 #999",
          letterSpacing: -15,
        }}
      >
        {num}
      </div>
      <div className="md:col-span-2 grid items-center">
        <h5 className="font-bold text-lg text-primary-main mb-3.5">{title}</h5>
        <ul className="text-white text-lg list-disc list-inside">
          {list?.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
