import React from "react";
import HERO_IMAGE from "../../assets/images/about-us/hero.jpeg";
import Navbar from "../Navbar";

export default function AboutHero() {
  return (
    <section
      className="bg-cover bg-center z-0 h-[570px] bg-fixed bg-blend-overlay w-full relative"
      style={{
        backgroundImage: `url(${HERO_IMAGE}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)`,
      }}
    >
      <Navbar className="bg-black/50" />
      <div className="container flex justify-center items-center h-full">
        <div>
          <h1 className="text-white font-normal text-4xl md:text-8xl">
            ABOUT US
          </h1>
        </div>
      </div>
    </section>
  );
}
