import React from "react";

export default function TitleBar({ title, mainTitle }) {
  return (
    <div className="md:pl-20 mb-10 md:mb-0">
      <div className="flex items-center gap-5 text-white mb-4 md:mb-10">
        <span className="w-12 h-[2px] bg-primary-main mt-[2px]" />
        <span>{title}</span>
      </div>
      <h3 className="font-bold text-3xl md:text-5xl text-white">{mainTitle}</h3>
    </div>
  );
}
