import React from "react";
import { Slide } from "react-awesome-reveal";
import img from "../../assets/images/about/4.jpeg";
import bgImage from "../../assets/images/dark-bg.jpeg";
import TitleBar from "../common/TitleBar";

export default function MissionVision() {
  return (
    <section
      className="relative z-20 bg-center bg-cover bg-no-repeat bg-fixed bg-blend-darken"
      style={{
        backgroundImage: `linear-gradient(rgba(2, 0, 200, 0.6),rgba(4, 0, 200, 0.6)),url(${bgImage})`,
      }}
    >
      <div className="grid md:grid-cols-12 items-center">
        <div className="hidden md:block md:col-span-5">
          <Slide direction="left">
            <img
              src={img}
              className="w-full h-[550px] object-cover"
              alt="..."
            />
          </Slide>
        </div>
        <div className="md:col-span-7 py-10">
          <div className="px-4 md:px-0">
            <TitleBar
              title="How We Think"
              mainTitle={
                <>
                  <span className="text-primary-main">Our</span>{" "}
                  <br className="hidden md:inline-block" />
                  <span className="pl-2 md:pl-0">Mission & Vision</span>
                </>
              }
            />
          </div>

          <div className="grid md:grid-cols-2 gap-5 mt-7 px-4 md:px-0 md:pl-10 md:pr-20">
            <MissionVisionCard
              title="Mission"
              subTitle="Cultivating Excellence In Support"
              description="We are dedicated to curating a team of exceptional talents who
              provide unparalleled support to organizations, enabling their
              success and driving sustainable growth."
            />

            <MissionVisionCard
              title="Vision"
              subTitle="Empowering Global Connection"
              description="Our vision is to foster seamless collaboration and connectivity worldwide, bringing people and organizations closer together."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const MissionVisionCard = ({ title, subTitle, description }) => {
  return (
    <div className="bg-dark-gray px-6 py-8 rounded-2xl text-center text-white drop-shadow-md">
      <h3 className="text-white font-medium font-serif text-4xl">{title}</h3>
      <h6 className="pt-6 pb-4 text-primary-main font-medium text-lg">
        {subTitle}
      </h6>
      <p className="text-white">{description}</p>
    </div>
  );
};
