import React, { useState } from "react";
import { Slide } from "react-awesome-reveal";
import ICON7 from "../../assets/icons/AccountingIcon.svg";
import ICON1 from "../../assets/icons/AnalyticsIcon.svg";
import ICON4 from "../../assets/icons/AnnotationTaggingIcon.svg";
import ICON8 from "../../assets/icons/BackOfficeIcon.svg";
import ICON3 from "../../assets/icons/ContentIcon.svg";
import ICON6 from "../../assets/icons/EcommerceIcon.svg";
import ICON9 from "../../assets/icons/LeadGenerationIcon.svg";
import ICON5 from "../../assets/icons/MenuBuildingIcon.svg";
import ICON2 from "../../assets/icons/ProcessingIcon.svg";
import ICON11 from "../../assets/icons/PropertyManagementIcon.svg";
import ICON10 from "../../assets/icons/VirtualAssistanIcon.svg";
import IMG from "../../assets/images/about/4.jpeg";

export default function Services() {
  const [serviceId, setServiceId] = useState("");
  // const serviceListData = serviceDataList?.map((item) => {
  //   const title = item.title.split("<br /> ");

  //   return {
  //     ...item,
  //     title: title.join(""),
  //   };
  // });

  function getTitleData(title = "") {
    return title?.split("<br /> ").join("");
  }

  const serviceItem = serviceDataList?.find((el) => el?.id === serviceId);

  return (
    <section className="pt-0 md:pt-20 md:pb-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-14 items-center">
        <div className="hidden md:block">
          <Slide direction="left">
            <div
              className="relative"
              style={{
                clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
              }}
            >
              <img className="h-[700px] w-full object-cover" src={IMG} alt="" />
              {serviceItem ? (
                <div className="flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 w-full h-full">
                  <div
                    className="bg-black/60 rounded-md w-3/4 h-[80%]"
                    style={{
                      clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
                    }}
                  >
                    <div className="px-14 py-16">
                      <img
                        className="w-12 h-12 text-primary-main"
                        src={serviceItem?.icon}
                        alt=""
                      />

                      <h3 className="text-primary-main font-bold text-2xl mt-3 mb-5">
                        {getTitleData(serviceItem?.title)}
                      </h3>

                      <p className="text-white pr-8">{serviceItem?.details}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Slide>
        </div>
        <div className="px-10 md:px-0 md:pr-10">
          <h3 className="font-bold text-3xl text-white text-center md:text-left md:pl-20">
            <span className="text-primary-main">
              Services <br /> We’re Offering
            </span>
          </h3>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-5 md:gap-y-7 mt-7">
            {serviceDataList.map((el) => (
              <div
                className="bg-dark-normal relative p-4 cursor-pointer select-none transition-transform duration-300 hover:scale-105"
                key={el.id}
                onClick={() => setServiceId(el.id)}
              >
                <div
                  className="h-14 w-[4.5rem] absolute top-2/4 -translate-y-2/4 -left-9"
                  style={{
                    filter: "drop-shadow(0px 0px 2px #111)",
                  }}
                >
                  <span
                    className="bg-dark-main h-full w-full flex justify-center items-center"
                    style={{
                      clipPath: "polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%)",
                    }}
                  >
                    <img
                      className="w-8 h-8 text-primary-main"
                      src={el.icon}
                      alt=""
                    />
                  </span>
                </div>
                <span
                  className="text-white font-bold block pl-[30px] text-sm"
                  dangerouslySetInnerHTML={{ __html: el.title }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const serviceDataList = [
  {
    id: 1,
    title: `Data & <br /> Analytics`,
    icon: ICON1,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 2,
    title: "Data <br /> Processing",
    icon: ICON2,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 3,
    title: "Content <br /> Moderation",
    icon: ICON3,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 4,
    title: "Annotation <br /> Tagging & Labeling",
    icon: ICON4,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 5,
    title: "Menu <br /> Building",
    icon: ICON5,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 6,
    title: "E-commerce <br /> Support",
    icon: ICON6,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 7,
    title: "Accounting & <br /> Bookkeeping",
    icon: ICON7,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 8,
    title: "Back-Office <br /> Support",
    icon: ICON8,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 9,
    title: "Lead <br /> Generation",
    icon: ICON9,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 10,
    title: "Virtual <br /> Assistant",
    icon: ICON10,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
  {
    id: 11,
    title: "Property <br /> Management",
    icon: ICON11,
    details:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non distinctio praesentium aliquid, vel in officiis ut quidem quasi id natus voluptatem, hic minus corrupti adipisci beatae dolore ab quisquam tempore.",
  },
];
