import React from "react";
import AboutHero from "../components/About/AboutHero";
import WhoWeAre from "../components/About/WhoWeAre";
import AppLayout from "../layouts/AppLayout";

export default function About() {
  return (
    <AppLayout title="About Us">
      <AboutHero />
      <WhoWeAre />
    </AppLayout>
  );
}
